<template>
    <b-modal ref="modal" :title="$t('general.added_cash_registers')" size="lg" @hide="hide">
        Stranka ima paket, ki dovoljuje samo eno blagajno. Odstranite ali onemogočite spodnje blagajne, da lahko dodate novo:

        <ul>
            <li v-for="item in firstFiveCashRegisters" :key="item.id">
                {{item.cash_register_id}}
            </li>
        </ul>
        <template #modal-footer>
            <div class="d-flex justify-content-end">
                <div>
                    <b-button @click="hide" variant="danger">
                        V redu
                    </b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import '@core/scss/vue/libs/vue-select.scss'

    export default {
        components: {
            BModal,
            BButton
        },
        data() {
            return {
                confirmTrue: false,
                clientId: null,
                activeCashRegisters: null
            }
        },
        computed: {
            firstFiveCashRegisters() {
                if (this.activeCashRegisters) {
                    const arr = this.activeCashRegisters.slice(0, 5)
                    arr.push({'id': 'unique_id', 'cash_register_id': '...'})
                    return arr
                }
                return null
            }
        },
        methods: {
            async loadData() {
                try {
                    const response = await this.$http.get(`/api/admin/v1/cash_registers/active/${this.clientId}`)
                    this.activeCashRegisters = response.data ?? []
                } catch (err) {
                    this.$printError('Prišlo je do napake!')
                }
            },
            async show(clientId) {
                this.clientId = clientId
                await this.loadData()
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>