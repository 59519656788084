<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Blagajne">
            <basic-table :columns="columns" :data="cash_registers" v-slot="props" v-if="loaded">
                <span v-if="props.column.displayType === 4" class="d-flex justify-content-center">
                    <span v-if="props.row[props.column.field]">
                        {{props.row[props.column.field]}}
                    </span>
                    <span v-else>
                        /
                    </span>
                </span>
                <span v-else-if="props.column.displayType === 5" class="d-flex justify-content-center">
                    <b-badge variant="success" v-if="props.row[props.column.field]">
                        Da
                    </b-badge>
                    <b-badge variant="danger" v-else>
                        Ne
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 6" class="d-flex justify-content-center">
                    <b-badge variant="primary" v-if="props.row[props.column.field] === 0">
                        Ni podatka
                    </b-badge>
                    <b-badge variant="danger" v-else-if="props.row[props.column.field] === 1">
                        Ne
                    </b-badge>
                    <b-badge variant="success" v-else-if="props.row[props.column.field] === 2">
                        Da
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 7" class="d-flex justify-content-center">
                    <b-badge variant="warning" v-if="props.row[props.column.field]">
                        Da
                    </b-badge>
                    <b-badge variant="success" v-else>
                        Ne
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 8" class="d-flex justify-content-center">
                    <b-button :variant="props.row.disabled? 'success': 'danger'" class="m-0 disableBadge" style="padding: 5px 5px;" @click="changeStatusOfCashRegister(props.row.disabled, props.row.id, props.row.client_id)">
                        <span v-if="props.row.disabled">
                            Omogoči
                        </span>
                        <span v-else>
                            Onemogoči
                        </span>
                    </b-button>
                </span>
                <span v-else-if="props.column.displayType === 9" class="d-flex justify-content-center">
                    <b-badge variant="secondary" v-if="props.row.device_type === CashRegisterType.Unknown">
                        Ni podatka
                    </b-badge>
                    <b-badge style="background: #1ab7ea;" v-else-if="props.row.device_type === CashRegisterType.Windows">
                        Windows
                    </b-badge>
                    <b-badge variant="success" v-else-if="props.row.device_type === CashRegisterType.MobileAndroid">
                        Android Mobile
                    </b-badge>
                    <b-badge  style="background: #52bb52;" v-else-if="props.row.device_type === CashRegisterType.DesktopAndroid">
                        Android Desktop
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 10" class="d-flex justify-content-center">
                    <b-badge variant="warning" v-if="props.row[props.column.field]">
                        Ne
                    </b-badge>
                    <b-badge variant="success" v-else>
                        Da
                    </b-badge>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>

        </b-card>
        <EssentialModal ref="essentialModal"></EssentialModal>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import {BCard, BButton, BOverlay, BBadge} from 'bootstrap-vue'
    import EssentialModal from '@/views/components/EssentialModal.vue'
    import {CashRegisterType} from '@/libs/enums/CashRegisters'

    export default {
        components: {
            EssentialModal,
            BasicTable,
            BButton,
            BCard,
            BOverlay,
            BBadge
        },
        computed: {
            CashRegisterType() {
                return CashRegisterType
            }
        },
        data() {
            return {
                loaded: false,
                cash_registers: [],
                versions: [],
                clients: [],
                businessUnits: [],
                columns: [
                    {
                        label: 'Oznaka blagajne',
                        field: 'cash_register_id',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Aktivna',
                        field: 'status',
                        displayType: 6,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Da', 'Ne', 'Ni podatka'],
                            filterFn: this.statusFilter,
                            customFilter: true
                        }
                    },
                    {
                        label: 'Onemogočena',
                        field: 'disabled',
                        displayType: 7,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Da', 'Ne'],
                            filterFn: this.boolFilter,
                            filterValue: 'Ne',
                            customFilter: true
                        },
                        sortable: false
                    },
                    {
                        label: 'Naprava',
                        field: 'device_type',
                        displayType: 9,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems:  [
                                {
                                    value: CashRegisterType.Windows,
                                    text: 'Windows'
                                },
                                {
                                    value: CashRegisterType.MobileAndroid,
                                    text: 'Android Mobile'
                                },
                                {
                                    value: CashRegisterType.DesktopAndroid,
                                    text: 'Android Desktop'
                                },
                                {
                                    value: CashRegisterType.Unknown,
                                    text: 'Ni podatka'
                                }
                            ],
                            filterFn: this.deviceTypeFilter,
                            label: 'text'
                        },
                        sortable: false
                    },
                    {
                        label: 'Stranka',
                        field: 'client',
                        displayType: 1,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Aktivna str.',
                        field: 'client_disabled',
                        displayType: 10,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Da', 'Ne'],
                            filterFn: this.boolReverseFilter,
                            filterValue: 'Da'
                        },
                        width: '150px',
                        sortable: false
                    },
                    {
                        label: 'Poslovni prostor',
                        field: 'business_unit',
                        displayType: 2,
                        filterOptions: {
                            enabled: true
                        },
                        sortable: false
                    },
                    {
                        label: 'Verzija',
                        field: 'latest_version',
                        displayType: 4,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: this.versions
                        },
                        sortable: false
                    },
                    {
                        label: 'Sinhronizacija',
                        field: 'enable_sync',
                        displayType: 5,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Da', 'Ne'],
                            filterFn: this.boolFilter
                        },
                        sortable: false
                    },
                    {
                        label: 'Aktivno v zadnjem tednu',
                        field: 'active_in_last_week',
                        displayType: 5,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Da', 'Ne'],
                            filterFn: this.boolFilter
                        },
                        sortable: false
                    },
                    {
                        label: 'Akcije',
                        field: 'enabled',
                        displayType: 8,
                        sortable: false
                    }
                ],
                showLoader: false

            }
        },
        methods: {
            async changeStatusOfCashRegister(disabled, cashRegisterId, clientId) {
                let status = ''

                if (disabled) {
                    status = 'enable'
                } else {
                    status = 'disable'
                }

                try {
                    await this.$http.post(`/api/admin/v1/cash_registers/status/${  status  }/${  clientId  }/${  cashRegisterId}`)

                    const index = this.cash_registers.findIndex(ele => ele.id === cashRegisterId)

                    if (index >= 0) {
                        this.cash_registers[index].disabled = !disabled
                    }

                    if (disabled) {
                        this.$printSuccess('Blagajna je bila uspešno omogočena')
                    } else {
                        this.$printSuccess('Blagajna je bila uspešno onemogočena')
                    }

                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'insufficient tier')) {
                        await this.$refs.essentialModal.show(clientId)
                    } else {
                        this.$printError('Prišlo je do napake pri spremembi')

                    }
                }
            },
            boolFilter(data, filterString) {
                if (filterString === 'Da' && data) {
                    return true
                } else if (filterString === 'Ne' && !data) {
                    return true
                }

                return false
            },
            boolReverseFilter(data, filterString) {
                return !this.boolFilter(data, filterString)
            },
            statusFilter(data, filterString) {
                if (filterString === 'Da' && data === 2) {
                    return true
                } else if (filterString === 'Ne' && data === 1) {
                    return true
                } else if (filterString === 'Ni podatka' && data === 0) {
                    return true
                }

                return false
            },
            deviceTypeFilter(data, filter) {
                const filterString = filter.text
                if (filterString === 'Windows' && data === CashRegisterType.Windows) {
                    return true
                }
                if (filterString === 'Android Mobile' && data === CashRegisterType.MobileAndroid) {
                    return true
                }
                if (filterString === 'Android Desktop' && data === CashRegisterType.DesktopAndroid) {
                    return true
                }
                if (filterString === 'Ni podatka' && data === CashRegisterType.Unknown) {
                    return true
                }

                return false
            },
            async loadData() {
                this.showLoader = true

                try {
                    const response = await this.$http.get('/api/admin/v1/cash_registers/all_cash_registers')
                    this.cash_registers = response.data ?? []


                    const versionMap = {}
                    const clientMap = {}
                    const businessUnitMap = {}
                    for (const cashRegister of this.cash_registers) {
                        if (!versionMap[cashRegister.latest_version]) {
                            if (cashRegister.latest_version === '') {
                                this.versions.push('Brez verzije')
                            } else {
                                this.versions.push(cashRegister.latest_version)
                            }
                        }

                        if (!clientMap[cashRegister.client]) {
                            this.clients.push(cashRegister.client)
                        }

                        if (!businessUnitMap[cashRegister.business_unit]) {
                            this.businessUnits.push(cashRegister.business_unit)
                        }

                        clientMap[cashRegister.client] = true
                        businessUnitMap[cashRegister.business_unit] = true
                        versionMap[cashRegister.latest_version] = true
                    }

                    const index = this.columns.findIndex(ele => ele.field === 'latest_version')
                    this.columns[index].filterOptions.filterDropdownItems = this.versions

                    const indexClient = this.columns.findIndex(ele => ele.field === 'client')
                    this.clients.sort((a, b) => a.localeCompare(b))
                    this.columns[indexClient].filterOptions.filterDropdownItems = this.clients

                    const indexBusinessUnit = this.columns.findIndex(ele => ele.field === 'business_unit')
                    this.columns[indexBusinessUnit].filterOptions.filterDropdownItems = this.businessUnits
                } catch {
                    this.$printError('Prišlo je do napake pri prenosu podatkov')
                } finally {
                    this.showLoader = false
                    this.loaded = true
                }

            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
.dark-layout .disableBadge span {
    color: white!important;
}
</style>
